<template>
  <v-card flat class="px-5 mx-sm-5 mt-10">
    <v-card-title> {{ $t("Technologies") }} </v-card-title>
    <v-card-text>
      <v-card
        flat
        v-for="(material2, technology, index) in technologies"
        :key="index"
      >
        <v-divider v-if="index > 0"></v-divider>
        <v-row align="start">
          <v-col cols="4" v-if="supplierTechnologies">
            <v-switch
              class="ml-5"
              inset
              color="success"
              :input-value="!!supplierTechnologies[technology]"
              @change="
                supplierTechnologies[technology]
                  ? delete supplierTechnologies[technology]
                  : resetMaterialsOfTechno(technology);
                forceRerender();
                technologyModified = true;
              "
              :label="technology"
            ></v-switch>
          </v-col>
          <v-col
            v-if="supplierTechnologies[technology]"
            class="materialCheckboxes"
          >
            <v-row
              v-for="material in technologies[technology]"
              :key="material"
              class="my-2"
            >
              <v-col cols="3">
                <input
                  type="checkbox"
                  :ref="technology"
                  :id="technology + material"
                  name="scales"
                  :value="material"
                  @click="
                    updateMaterialCheck(material, technology);
                    technologyModified = true;
                  "
                  :checked="supplierTechnologies[technology].includes(material)"
                >
                <label :key="keyComponent" :for="technology + material">{{
                  $t(material)
                }}</label>
              </v-col>
              <v-col>
                <v-textarea
                  v-if="supplierTechnologies[technology].includes(material)"
                  outlined
                  :rows="pricesConfigurations[technology][material].trim() === '' ? 1 : 3"
                  :label="$t('PriceConfigurationFormula')"
                  v-model="pricesConfigurations[technology][material]"
                  @change="technologyModified = true;"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-card-text>
    <div class="pb-4 pl-3">
      <v-btn
        color="error"
        class="text-none mr-2"
        :title="$t('Cancel')"
        @click="getSupplier"
      >
        <v-icon small class="mr-1">
          fas fa-ban
        </v-icon>
        {{ $t('Cancel') }}
      </v-btn>
      <v-btn
        :disabled="!technologyModified"
        color="success"
        class="text-none"
        @click="modifySupplierTechnologies"
        :title="$t('valid')"
      >
        <v-icon small class="mr-1">
          fas fa-check
        </v-icon>
        {{ $t('valid') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSupplierTechnologies.i18n.json');

export default {
  name: 'PageSupplierTechnologies',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      technologyModified: false,
      keyComponent: 0,
      supplier: null,
      supplierTechnologies: [],
      allTechnology: null,
      technologies: {},
      materials: null,
      currentModified: null,
      allMaterials: null,
      /**
       * Prices Configuration
       */
      pricesConfigurations: null,
    };
  },
  created() {
    this.getAllTechnologiesAndMaterials();
  },
  methods: {
    getAllTechnologiesAndMaterials() {
      this.$apiInstance.getAllTechnologiesAndMaterialsCompatibilities().then(data => {
        for(const technologyObj of data) {
          this.$set(this.technologies, technologyObj.technology, technologyObj.materials.map(materialObj => {
            return materialObj.material;
          }));
        }
        /**
         * GET SUPPLIER
         */
        this.getSupplier();
      });
    },
    /**
     * GET SUPPLIER
     */
    getSupplier() {
      this.$apiInstance
        .getSupplier(this.$route.params.supplierUUID)
        .then(
          (supplierData) => {
            this.pricesConfigurations = null;
            this.supplier = supplierData;
            if (supplierData.technologies) {
              this.supplierTechnologies = supplierData.technologies;
              for(const technology of Object.keys(this.supplierTechnologies)) {
                if(!this.technologies[technology]) {
                  delete this.supplierTechnologies[technology];
                } else {
                  this.supplierTechnologies[technology] = this.supplierTechnologies[technology].filter(material => {
                    return this.technologies[technology].includes(material);
                  });
                }
              }
            }
            this.formatPricesConfigurations();
          },
          (error) => {
            /**
             * ERROR GET PRINTER
             */
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.technologyModified = false;
        });
    },
    formatPricesConfigurations() {
      const pricesConfigurations = {};
      /**
       * Init technologies
       */
      Object.keys(this.technologies).forEach(technology => {
        pricesConfigurations[technology] = {};
        /**
         * Init materials for the technology
         */
        this.technologies[technology].forEach(material => {
          pricesConfigurations[technology][material] = '';
        });
      });
      /**
       * Parse supplier price configuration formula and fill the previous dictionnary
       */
      this.supplier.prices.forEach(pConfig => {
        pricesConfigurations[pConfig.technology][pConfig.material] = pConfig.formula;
      });
      /**
       * Save dictionnary
       */
      this.pricesConfigurations = pricesConfigurations;
    },
    /**
     * MODIFY SUPPLIER TECHNOLOGIES
     */
    modifySupplierTechnologies() {
      /**
       * Update technologies
       */
      const modifySupplierTechnologiesBody = new this.$BcmModel.ModifySupplierTechnologiesBody(
        this.supplierTechnologies
      );
      this.$apiInstance
        .modifySupplierTechnologies(
          this.$route.params.supplierUUID,
          modifySupplierTechnologiesBody
        )
        .then(
          () => {
            /**
             * Create pricesConfigurations object
             */
            const pricesConfigurationsBody = [];
            Object.keys(this.pricesConfigurations).forEach(technology => {
              Object.keys(this.pricesConfigurations[technology]).forEach(material => {
                if(this.pricesConfigurations[technology][material] && this.pricesConfigurations[technology][material].trim() !== '') {
                  pricesConfigurationsBody.push({
                    technology,
                    material,
                    formula: this.pricesConfigurations[technology][material]
                  });
                }
              });
            });
            this.$apiInstance.modifySupplierPricesConfigurations(this.$route.params.supplierUUID, pricesConfigurationsBody).then(() => {
              this.$notification.notify('SUCCESS',this.$t('SupplierTechnologiesSuccessfullyModified'));
            }, (error) => {
              /**
               * ERROR MODIFY PRINTER
               */
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }).finally(()=>{
              this.getSupplier();
            });
          },
          (error) => {
            /**
             * ERROR MODIFY PRINTER
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            this.getSupplier();
          }
        );
    },
    isEmpty(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },
    resetMaterialsOfTechno(technology) {
      if (!this.isEmpty(this.supplierTechnologies)) {
        this.supplierTechnologies[technology] = this.technologies[
          technology
        ].slice();
      } else {
        this.supplierTechnologies = {};
        this.supplierTechnologies[technology] = [];
        for (let i = 0; i < this.technologies[technology].length; i++) {
          this.supplierTechnologies[technology].push(
            this.technologies[technology][i]
          );
        }
      }
    },
    updateMaterialCheck(material, technology) {
      this.supplierTechnologies[technology].includes(material) ? this.supplierTechnologies[technology].splice(this.supplierTechnologies[technology].indexOf(material), 1) : this.supplierTechnologies[technology].push(material);
      if (this.supplierTechnologies[technology].length === 0) {
        delete this.supplierTechnologies[technology];
      }
      this.forceRerender();
    },
    forceRerender() {
      this.keyComponent += 1;
    },
  },
};
</script>


<style lang="scss">
.materialCheckboxes {
    [type='checkbox']:not(:checked),
    [type='checkbox']:checked {
      /* Cache la checkbox sans
	   la désactiver pour les
	   lecteurs d'écran */
      position: absolute;
      left: 0;
      opacity: 0.01;
    }

    /* on prépare le label */
    [type='checkbox']:not(:checked) + label,
    [type='checkbox']:checked + label {
      position: relative; /* permet de positionner les pseudo-éléments */
      padding-left: 2.3em; /* fait un peu d'espace pour notre case à venir */
      font-size: 1.05em; /* Label un peu plus gros */
      line-height: 1.7;
      cursor: pointer; /* affiche un curseur adapté */
    } /* Aspect de la case */
    [type='checkbox']:not(:checked) + label:before,
    [type='checkbox']:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1.4em;
      height: 1.4em;
      border: 2px solid #aaa;
      background: #fff;
      border-radius: 0.2em;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
        0 0 0 rgba(22, 159, 133, 0.2);
      transition: all 0.275s;
    }

    /* Aspect de la coche */
    [type='checkbox']:not(:checked) + label:after,
    [type='checkbox']:checked + label:after {
      content: '✓';
      position: absolute;
      top: 0.45em;
      left: 0.1em;
      font-size: 1.6em;
      color: rgba(22, 159, 133, 1);
      line-height: 0;
      transition: all 0.2s; /* Petite transition */
    }

    /* Aspect non cochée */
    [type='checkbox']:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0) rotate(45deg);
    }

    /* Aspect cochée */
    [type='checkbox']:checked + label:after {
      opacity: 1;
      transform: scale(1) rotate(0);
    } /* Disabled checkbox */
    [type='checkbox']:disabled:not(:checked) + label:before,
    [type='checkbox']:disabled:checked + label:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #e9e9e9;
    }

    /* Disabled checked */
    [type='checkbox']:disabled:checked + label:after {
      color: #777;
    }

    [type='checkbox']:disabled + label {
      color: #aaa;
    }

    /* Accessibility focus */
    [type='checkbox']:checked:focus + label:before,
    [type='checkbox']:not(:checked):focus + label:before {
      box-shadow: inset 0 1px 3px rgba(22, 159, 133, 0.1),
        0 0 0 6px rgba(22, 159, 133, 0.2);
    }
  }
</style>